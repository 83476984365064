import lottie from 'lottie-web';
import heroAnimation from '../lottie/IndartisAnim_Master.json';
import industryStatsAnimation from '../lottie/homepage-global-trade.json';

lottie.loadAnimation({
  container: document.getElementById('lottie'),
  renderer: 'svg',
  loop: true,
  autoplay: true,
  animationData: heroAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  }
}).setSubframe(false);

// const jsonUrl = "https://indartis-tests.s3.us-east-2.amazonaws.com/IndartisAnim_Master.json";
// $.get(jsonUrl, (data) => {
//   lottie.loadAnimation({
//     container: document.getElementById('lottie'),
//     renderer: 'svg',
//     loop: true,
//     autoplay: true,
//     animationData: data,
//     rendererSettings: {
//       preserveAspectRatio: 'xMidYMid slice',
//     }
//   }).setSubframe(false);
// });

//const statsLottie = lottie.loadAnimation({
//  container: document.getElementById('lottie-trade-industry'),
//  renderer: 'svg',
//  loop: false,
//  autoplay: false,
//  animationData: industryStatsAnimation,
//  rendererSettings: {
//    preserveAspectRatio: 'xMidYMid meet',
//  }
//});
//
//$(funtion() {
//  // Some logic to play the stats animation once down the page enough
//});
